import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, delay, setIsComplete, onType }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [words, setWords] = useState([]);
  const [isTyping, setIsTyping] = useState(true);
  const [tIndex, setTIndex] = useState(words.length)

  useEffect(() => {
    setWords(text.split(' '));
  }, [text]);

  useEffect(() => {
    let trigger = tIndex
    if (currentIndex < words.length) {
      setIsComplete(true);
      const timeout = setTimeout(() => {
        if(isTyping){
          setCurrentIndex(currentIndex + 1);
          setCurrentText(prevText => prevText + ' ' + words[currentIndex]);
        } else{
         console.log('in', trigger) 
        }
        
        onType();
        console.log('words', words)
        // setIsLoading(isTyping)
      }, delay);
  
      return () => clearTimeout(timeout);

    } else {
      // setCurrentIndex(prevIndex => prevIndex - 1 );
      setIsComplete(false);
    }
  }, [currentIndex, delay, words, onType]);

  return <span dangerouslySetInnerHTML={{ __html: currentText }}></span>;
};

export default Typewriter;