import React, { useState, useRef, useEffect } from 'react'
import logo from '../assets/logo.svg'
import userIcon from '../assets/userIcon.svg'
import aiIcon from '../assets/aiIcon.svg'
import darkUserIcon from '../assets/darkUserIcon.svg'
import moment from 'moment'
import Typewriter from '../components/Typewriter'
import { getMessages } from '../ApiController'

function Chat({ handleThemeChange, theme }) {
    const chatContainerRef = useRef(null);
    const [chats, setChats] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showNavbar,setShowNavbar] = useState(true)
    const [isComplete, setIsComplete] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(true);

    const faq = [
        { question: 'Is IBM a good stock?', ans: 'Using our stock evaluator, Genie explains stock’s health.' }, 
        { question: 'Today’s top 20 good stocks', ans: 'Get a list of today’s 20 good stocks.' },
        { question: 'Stocks between $4 to $20', ans: 'List of stellar stocks within a small price range.' },
        { question: 'What is the current price of IBM', ans: 'Get the current price of IBM.' },
        { question: 'What are the best fundamental stocks?', ans: 'Get a list of best fundamental stocks.' },
        { question: 'What are potential covered calls', ans: 'Get a list of potential covered calls.' },
        { question: 'What is the current target price of IBM', ans: 'Get the current target price of IBM.' },
        { question: 'What are high-yield dividend stocks today?', ans: 'Get a list of high-yield dividend stocks.' },
        { question: 'What does p/e ratio mean', ans: 'Search the results for p/e ratio.' },
    ]

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const navbar = urlParams.get('navbar');
        if(navbar == 'true'){
            setShowNavbar(true)
        }else if(navbar == 'false'){
            setShowNavbar(false)
        }
      }, []);

    const scrollToBottom = () => {
        // console.log('chatContainerRef.current',chatContainerRef.current.scrollTop)
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            console.log("height==>", chatContainerRef.current.scrollTop)
            console.log("top==>", chatContainerRef.current.scrollTop)
        }
    };

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const isUserAtBottom = chatContainerRef.current.scrollTop + chatContainerRef.current.clientHeight >= chatContainerRef.current.scrollHeight - 1;
            setIsAtBottom(isUserAtBottom);
        }
    };

    // Add scroll event listener on mount
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.addEventListener('scroll', handleScroll);
        }

        // Cleanup on unmount
        return () => {
            if (chatContainerRef.current) {
                chatContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        // Scroll to the bottom of the chat container
        scrollToBottom();
    }, [chats]); //

    const toggleTheme = () => {
        handleThemeChange()
    }

    const getCurrentTime = () => {
        return moment().format('YYYY-MM-DD HH:mm:ss');
    }

    const convertTime = (val) => {
        return moment(val)?.fromNow()
    }

    //const submitQuestion = ()=>{
    //  let message = [{message:question,user:'Mike C.',time:getCurrentTime(),type:'user'},{message:'Hello! I am an Incometrader’s AI Genie, ready to assist you with stock queries. How can I help you?',user:'AI Genie',time:getCurrentTime(),type:'bot'}]
    //setChats([...chats,...message])
    //setQuestion('')
    //}

    const handleLogout = () => {
        setChats([])
        setIsComplete(false)
    }

    const handleSend = (question) => {
        submitQuestion(question)
    }

    // const submitQuestion = async (q="") => {

    //     let userMessage = { message:q ? q : question, user: 'Mike C.', time: getCurrentTime(), type: 'user' };

    //     setChats([...chats, userMessage]);
    //     setQuestion('');
    //     // console.log("hello",userMessage)

    //     try {
    //         // const response = await axios.post('https://chatbot-api.successstockmarket.com/get_stock_data', { symbol: q ? q : question });
    //         const response = await getStockData(q ? q : question);
    //         // console.log("response",response.message)
    //         //let botMessage = { message: response.data.message, user: 'AI Genie', time: response.data.time, type: 'bot' };
    //         const { message, chart_url } = response;
    //         console.log("AI response",response)

    //     let botMessage = { 
    //         message, 
    //         chart_url, 
    //         user: 'AI Genie', 
    //         time: getCurrentTime(), 
    //         type: 'bot' 
    //     };
    //         setChats((prevChats) => [...prevChats, botMessage]);
    //     } catch (error) {
    //         console.error('Error getting response from bot:', error);
    //     }
    // };

    const submitQuestion = async (question) => {
        const name = 'Me';
        const sender_id = 'user';
        const userMessage = { sender: 'user', message: question, time: getCurrentTime(), name: name };

        if (question.trim()) {
            setChats((prevChats) => [...prevChats, userMessage]);
            setInputMessage('');
            // setIsLoading(true)

            try {
                const response = await getMessages(sender_id, question);
                console.log("AI response", response);
                // chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                if (response.length > 0) {
                    const botMessage = {
                        sender: 'bot',
                        message: response[0].text,
                        time: getCurrentTime(),
                        name: 'AI Genie',
                    };
                    scrollToBottom()
                    setChats((prevChats) => [...prevChats, botMessage]);
                    // if (chatContainerRef.current) {
                    //     chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                    //     console.log("height==>", chatContainerRef.current.scrollTop)
                    // }
                    
                    // setIsLoading(false)
                }
            } catch (error) {
                console.error('Error getting response from Rasa:', error);
                // setIsLoading(false)
            }
        } else {
            alert('Please enter a valid message');
            // setIsLoading(false)
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputMessage.trim()) {
            submitQuestion(inputMessage);
        }
    }



    return (
        <div className={`w-full h-full dark:bg-[#181818] px-4 ${showNavbar?'py-2':''}`}>
            {/* navbar  */}
            <div  className={`w-full h-[5%]  flex justify-between items-center ${showNavbar?'':'hidden'} `}>
                <div className='flex items-center space-x-4'>
                    <span>
                        <img src={logo} alt="logo" className='w-[65px] h-[41px] object-contain' />
                    </span>
                    <span onClick={handleLogout} className='text-[#000000] cursor-pointer navbarHeading dark:text-[#FFFFFFB2] text-[20px] font-[400]'>
                        Incometrader AI Genie 1.0
                    </span>

                </div>
                <div className='flex items-center space-x-1'>
                    <span onClick={toggleTheme} className='p-2 rounded-full hover:bg-[#F0F0F0] dark:hover:bg-[#FFFFFF0D] cursor-pointer'>
                        <svg width="23" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4004 13.6499C11.9504 13.2499 8.30044 9.74988 7.65044 5.34988C7.50044 4.44988 7.50044 3.49989 7.65044 2.64988C7.75044 1.94989 6.95044 1.49988 6.40044 1.84988C3.15044 3.84988 1.05044 7.44988 1.25044 11.4999C1.50044 16.8499 5.75044 21.2499 11.1004 21.6999C16.4004 22.1499 21.0004 18.6999 22.3504 13.8999C22.5504 13.1999 21.8504 12.5999 21.2004 12.8999C19.7504 13.5499 18.1504 13.7999 16.4004 13.6499Z" stroke="#707070" fill={`${theme ? '#707070' : ''}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <span onClick={handleLogout} className='p-2 rounded-full hover:bg-[#F0F0F0] dark:hover:bg-[#FFFFFF0D] cursor-pointer'>
                        <svg width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.04021 9.27852C9.04021 9.8087 9.47003 10.2385 10.0002 10.2385C10.5304 10.2385 10.9602 9.8087 10.9602 9.27852H9.04021ZM10.9602 1.7598C10.9602 1.22962 10.5304 0.799805 10.0002 0.799805C9.47003 0.799805 9.04021 1.22962 9.04021 1.7598H10.9602ZM6.55806 5.1666C7.04059 4.94689 7.25358 4.3776 7.03393 3.89508C6.81416 3.41255 6.24488 3.19952 5.76236 3.41925L6.55806 5.1666ZM1.04021 12.2046L0.0800781 12.1996L0.0802704 12.2157L1.04021 12.2046ZM10.0002 20.9598L10.0112 19.9998C10.0039 19.9998 9.99649 19.9998 9.9892 19.9998L10.0002 20.9598ZM18.9602 12.2046L19.9203 12.2157L19.9202 12.1996L18.9602 12.2046ZM14.238 3.41925C13.7555 3.19952 13.1863 3.41255 12.9665 3.89508C12.7468 4.3776 12.9598 4.94689 13.4424 5.1666L14.238 3.41925ZM10.9602 9.27852V1.7598H9.04021V9.27852H10.9602ZM5.76236 3.41925C2.3182 4.98759 0.0999053 8.41518 0.0800781 12.1996L2.00019 12.2096C2.0161 9.17404 3.79535 6.42464 6.55806 5.1666L5.76236 3.41925ZM0.0802704 12.2157C0.143374 17.6377 4.58937 21.982 10.0112 21.9198L9.9892 19.9998C5.62752 20.05 2.05091 16.5551 2.00014 12.1935L0.0802704 12.2157ZM9.9892 21.9198C15.411 21.982 19.8574 17.6377 19.9203 12.2157L18.0003 12.1935C17.9495 16.5551 14.3729 20.05 10.0112 19.9998L9.9892 21.9198ZM19.9202 12.1996C19.9004 8.41518 17.6823 4.98759 14.238 3.41925L13.4424 5.1666C16.205 6.42464 17.9843 9.17404 18.0002 12.2096L19.9202 12.1996Z" fill="#707070" />
                        </svg>

                    </span>

                </div>

            </div>

            <div className={`w-[100%] ${showNavbar?'h-[95%]':'h-[98%]'} flex items-center justify-center flex-col`}>
                {/* chat area  */}
                <div className={`h-[85%] ${showNavbar?'containerWidth':'w-full'}  flex flex-col  justify-end relative`}>
                    {chats?.length > 0 ? (
                        <div ref={chatContainerRef} className='w-full h-full chatArea space-y-4 flex flex-col overflow-y-auto no-scrollbar'>
                            {chats?.map((item, i) => (
                                <div className={`w-full flex  ${item.sender === 'user' ? 'justify-end' : 'justify-start'}`} key={i}>
                                    <div className={`w-full flex flex-col space-y-1.5 ${item.sender === 'user' ? 'items-end' : 'items-start'} `}>
                                        <div className={`flex items-center space-x-3 ${item.sender === 'user' ? 'pr-1' : 'pl-1'}`}>
                                            <div>
                                                <img src={item.sender === 'user' ? theme ? darkUserIcon : userIcon : aiIcon} className='object-contain' alt='Icons' />
                                            </div>
                                            <div className={`font-[600] text-[18px] ${item.sender === 'user' ? 'text-[#000000] dark:text-[#FFFFFF]' : isComplete ? 'loading-gradient-text' : 'gradient-text'}`} >
                                                {item.name}
                                            </div>
                                        </div>
                                        <div className={`px-5 py-3  ${item.sender === 'user' ? 'bg-[#9191911A] dark:text-[#FFFFFFCC] rounded-full text-[#000000CC]' : 'border rounded-tr-[20px] rounded-tl-[20px] rounded-br-[20px] rounded-bl-[20px] dark:border-[#FFFFFF33] text-[#000000B2] dark:text-[#FFFFFFB2]'}  text-[16px] font-[400]`}>
                                            { 
                                                item.sender === "user" ? item.message :
                                                    item.sender !== "user" && !isLoading ? 
                                                        <div className=''>
                                                          <button onClick={()=>scrollToBottom()} className={`absolute rounded-full border border-red-400 bottom-0 right-80 ${isAtBottom ? 'hidden' : 'block'}`}>hello</button>
                                                            <Typewriter text={item.message} delay={33} setIsComplete={setIsComplete}/>
                                                        </div>
                                                        :
                                                        <span class="ellipsis-anim font-[900]"><span className='ellipsis-dot font-[900]'>.</span><span className='ellipsis-dot font-[900]'>.</span><span className='ellipsis-dot font-[900]'>.</span></span>
                                            }
                                        </div>
                                        <div className={`text-[#8B8B8B] text-[12px] ${item.sender === 'user' ? 'pr-1' : 'pl-1'}`}>
                                            {convertTime(item.time)}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    ) : (
                        <>
                            <div className={`flex flex-col space-y-1  w-full ${showNavbar?'h-[60%] justify-center items-center':'h-[25%] items-start justify-start'}`}>
                                <div className={`${showNavbar?'':'hidden'}`}>
                                    <img src={logo} alt="logo" className='w-[10rem] h-[5rem] object-contain' />
                                </div>
                                <div className={`text-[#000000] dark:text-[#FFFFFF] aiHeading   ${showNavbar?'text-[32px] font-[400] pt-2':'text-[26px] font-bold'}`}>
                                    Welcome to Incometrader AI Genie!
                                </div>
                                <div className='text-[#777777] text-[12px] font-[400]'>
                                    Our AI Genie tool is one of our smart initiatives to leverage AI in our trading tool and training program. Your feedback will help us improve.
                                </div>
                            </div>
                            <div className={` w-full flex flex-col gap-y-6 items-end ${showNavbar?'h-[40%] justify-end':'h-[75%] justify-start'}`}>
                                <div className='w-full flex grid gap-4 overflow-y-auto no-scrollbar md:grid-cols-2'>
                                    {faq?.slice(0, 16).map((item, i) => (
                                    <div onClick={() => handleSend(item.question)} key={i} className='faqItem h-fit w-full bg-transparent border dark:border-[#FFFFFF33] rounded-[18px] flex flex-col items-start py-2 px-4 hover:bg-gray-100 dark:hover:bg-[#262626] cursor-pointer'>
                                        <div className='text-[#000000CC] dark:text-[#FFFFFFCC] faqQa font-[600] text-[16px]'>{item.question}</div>
                                        <div className='text-[#00000099] dark:text-[#FFFFFF99] faqText font-[400] text-[13px] -mt-1'>{item.ans}</div>
                                    </div>
                                    ))}
                                </div>
                            </div>

                        </>
                    )}

                </div>

                {/* search  */}
                <div className={`h-[15%] space-y-1  ${showNavbar?'containerWidth':'w-full'} flex flex-col pb-4 items-center justify-end -bg-blue-100`}>
                    <div className='w-full bg-[#f2f2f2] dark:bg-[#FFFFFF0D] px-6 h-[3.5rem] rounded-[2rem] flex items-center justify-between'>
                        <div className='w-10/12'>
                            <input type='text' value={(inputMessage)} onChange={(e) => setInputMessage(e.target.value)} disabled={isComplete ? true : false} onKeyDown={!inputMessage ? "" : handleKeyDown} placeholder='Ask questions to Incometrader Bot' className='bg-transparent w-full border-none placeholder:text-[#00000066] dark:placeholder:text-[#FFFFFF66] dark:text-[#FFFFFF66] text-[16px] focus:outline-none mb-4' />
                        </div>
                        <div className='w-2/12 flex justify-end'>
                            {isComplete ?
                                <svg onClick={() => setIsComplete(false)} className="w-5 h-5 text-black dark:text-white cursor-pointer hover:opacity-80" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="12.5" stroke="currentColor" stroke-opacity="0.3" stroke-width="2" />
                                    <rect x="8" y="8" width="10" height="10" fill="currentColor" fill-opacity="0.3" stroke="currentColor" stroke-opacity="0.3" stroke-width="2" />
                                </svg>
                                :
                                <svg onClick={!inputMessage ? "" : () => submitQuestion(inputMessage)} className={`w-5 h-5 text-black dark:text-white ${inputMessage.length > 0 ? 'cursor-pointer hover:opacity-80' : ''}`} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24.5 1.5L1.125 11.125L10.75 15.25M24.5 1.5L14.875 24.875L10.75 15.25M24.5 1.5L10.75 15.25" stroke="currentColor" stroke-opacity="0.3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            {/* <svg onClick={!inputMessage ? "" : ()=>submitQuestion(inputMessage)} className= {`w-5 h-5 text-black dark:text-white ${inputMessage.length>0?'cursor-pointer hover:opacity-80':''}`} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.5 1.5L1.125 11.125L10.75 15.25M24.5 1.5L14.875 24.875L10.75 15.25M24.5 1.5L10.75 15.25" stroke="currentColor" stroke-opacity="0.3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> */}

                        </div>
                    </div>

                </div>
                <div className=' text-[#000000B2] dark:text-[#FFFFFFB2] text-[12px] font-[400] text-center'>
                    All AI Genie suggestions are for reference only, and not to be considered a recommendation of any sort.<br/>
                    Designed & developed by <span><a target={"_blank"} className='text-[#000000CC] dark:text-[#FFFFFFCC] underline' href='https://dimensionalsys.com'>Dimensional Sys.</a></span>
                </div>

            </div>


        </div>
    )
}

export default Chat