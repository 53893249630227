// src/api.js
import axios from 'axios';
// import { io } from 'socket.io-client';

// const API_URL = "http://127.0.0.1:8000"; // Replace with your actual API URL
const API_URL = "https://chatbot-api.successstockmarket.com/webhooks/rest/webhook"; // Replace with your actual API URL
// const API_URL = "http://localhost:5005/webhooks/rest/webhook"; // Replace with your actual API URL
// const API_URL = "http://3.136.40.49:5005/webhooks/rest/webhook"; // Replace with your actual API URL

// const socket = io(API_URL);
// const getStockData = async (symbol) => {
//     try {
//       const response = await axios.post(`${API_URL}/get_stock_data`, { symbol });
//       return response.data;
//     } catch (error) {
//       console.error('Error Getting Stock Data:', error);
//       throw error;
//     }
//   };

const getMessages = async (senderId,userMessage) => {
  try {
    const response = await axios.post(API_URL, {
      sender: senderId,
      message: userMessage
    });
    return response.data;
  } catch (error) {
    console.error('Error sending message to Rasa:', error);
    return [];
  }
};
// const chatWithAi = (input, setResponse) => {
//   socket.emit('message', {input});
//   socket.on('response', (data) => {
//     console.log(data.response);
//     setResponse(data.response);
//   });
// }

export { getMessages };
