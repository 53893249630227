import { useState,useEffect } from "react";
import "./App.css";
import Chat from './pages/Chat'

function App() {
  const [theme,setTheme] = useState(false)

  useEffect(()=>{
    let x = localStorage.getItem('theme')
    if(x === 'true'){
      setTheme(true)
    }else{
      setTheme(false)
    }
  },[])

  const handleThemeChange = ()=>{
    localStorage.setItem('theme',!theme)
    setTheme(!theme)
  }
 
  return (
    <div className={`w-[100vw] h-[100vh]   ${theme?'dark':''}`}>
      {/* <iframe src="https://chatbot.successstockmarket.com/" width={500} height={751} className=""></iframe> */}
      <Chat handleThemeChange={handleThemeChange} theme={theme}/>
    </div>
  );
}

export default App;
